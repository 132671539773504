import { useGate, useStore, useStoreMap } from 'effector-react'
import React from 'react'
import { Form, settingsTelematicFormModel } from '~/entities/SettingsTelematic'
import { usePermissions } from '~/entities/viewer'
import { formButtonsModel } from '~/shared/ui/Form'
import {
  $carWhitelists,
  Gate,
  requestCarWhitelistFx,
  $requestCarWhitelistError,
  carsBatchAdd,
  carsBatchAddFx,
  carsBatchDelete,
  carsBatchDeleteFx,
  requestCarWhitelistWithoutLoadingFx,
} from './model'

export function SettingsTelematic() {
  useGate(Gate)
  const { canConfigUpdate } = usePermissions()

  const isLoading = useStore(requestCarWhitelistFx.pending)
  const carWhitelists = useStore($carWhitelists)

  const isAddPending = useStore(carsBatchAddFx.pending)
  const isDeletePending = useStore(carsBatchDeleteFx.pending)
  const isRequestCarWhitelistPending = useStore(
    requestCarWhitelistWithoutLoadingFx.pending,
  )

  const defaultValues = useStoreMap($carWhitelists, (carWhitelists) => ({
    carsWhitelists: carWhitelists.map(({ id, label }) => ({
      id,
      label,
    })),
  }))

  const isSubmitting =
    isAddPending || isDeletePending || isRequestCarWhitelistPending

  const requestCarPlateNumberWhitelistError = useStore(
    $requestCarWhitelistError,
  )

  const handleSuccess = ({
    carsWhitelists: formCarWhiteLists,
  }: settingsTelematicFormModel.FormValues) => {
    const addOptions = formCarWhiteLists.filter(
      (formCar) => !carWhitelists.some((car) => formCar.label === car.label),
    )
    const deletingOptions = carWhitelists.filter(
      (car) =>
        !formCarWhiteLists.some((formCar) => formCar.label === car.label),
    )

    if (addOptions?.length) carsBatchAdd(addOptions)
    if (deletingOptions?.length) carsBatchDelete(deletingOptions)

    if (addOptions?.length || deletingOptions?.length) return
    formButtonsModel.editingEnded()
  }

  return (
    <Form
      isSubmitting={isSubmitting}
      isLoading={isLoading}
      defaultValues={defaultValues}
      onSuccess={handleSuccess}
      canEdit={canConfigUpdate}
      getCarError={requestCarPlateNumberWhitelistError}
    />
  )
}
