import { carStatusEnumOptions } from '~/shared/config/constants'
import { Filter } from '~/shared/ui/AsyncGrid/types'

export const filters: Filter[] = [
  {
    field: 'plateNumber',
    label: 'Гос. номер',
    type: 'text',
  },
  {
    field: 'vin',
    label: 'VIN',
    type: 'text',
  },
  {
    field: 'status',
    label: 'Статус',
    type: 'select',
    options: carStatusEnumOptions,
  },
  {
    field: 'updatedAt',
    label: 'Дата обновления',
    type: 'date',
  },
]
