import { Box, ChipProps, BoxProps } from '@mui/joy'
import { Option } from '~/shared/config/constants'
import { ChipDelete } from '../ChipDelete'

type ChipListProps = {
  type: 'delete'
  chipProps?: Partial<ChipProps>
  sx?: BoxProps['sx']
  options: Option[]
} & { type: 'delete'; onDelete: (option: Option) => void }

export function ChipList({
  type,
  options,
  onDelete,
  chipProps,
  sx,
}: ChipListProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '12px',
        alignItems: 'center',
        flexWrap: 'wrap',
        ...sx,
      }}
    >
      {options.map(
        (option) =>
          type === 'delete' && (
            <ChipDelete
              key={option.id}
              onDelete={() => onDelete(option)}
              {...chipProps}
            >
              {option.label}
            </ChipDelete>
          ),
      )}
    </Box>
  )
}
