import * as z from 'zod'

import { Option } from '~/shared/config/constants'
import { ApiModel } from './core'

const schema = z.object({
  plateNumber: z.string(),
})

export type CarCarWhitelistAttributes = z.infer<typeof schema>

export type CarWhitelistOption = Option & {
  deleteId: UniqueId
}

export class CarWhitelist extends ApiModel<
  typeof schema,
  CarCarWhitelistAttributes
> {
  static jsonApiType = 'car-plate-number-whitelists'

  static schema = schema

  getPlateNumber() {
    return this.getAttribute('plateNumber')
  }

  getOption(): CarWhitelistOption {
    const plateNumber = this.getPlateNumber()
    return {
      id: plateNumber,
      label: plateNumber,
      deleteId: this.getApiId() as UniqueId,
    }
  }
}
