import { CarStatusEnum } from '~/shared/config/enums'

export const AUTH_TOKEN_STORAGE_KEY = 'auth_token'
export const AUTH_EMAIL_STORAGE_KEY = 'auth_email'
export const AUTH_CURRENT_USER_ID_STORAGE_KEY = 'auth_current_user_id'
export const USER_PERMISSIONS_STORAGE_KEY = 'user_permissions'

export const MIN_COLUMN_WIDTH = 90

export type Option = { id: string | number; label: string | number }
export type OptionEnum<T> = { id: T; label: string }

export const carStatusEnumLabels: Record<CarStatusEnum, string> = {
  [CarStatusEnum.BLOCKED]: 'Заблокирован',
  [CarStatusEnum.UNLOCKED]: 'Разблокирован',
}

export const carStatusEnumOptions: OptionEnum<CarStatusEnum>[] = Object.keys(
  CarStatusEnum,
).map((value) => {
  const label = carStatusEnumLabels[value as keyof typeof CarStatusEnum]
  return {
    label,
    id: value as CarStatusEnum,
  }
})
