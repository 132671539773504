import { Box, Breadcrumbs as JoyBreadcrumbs, Link, Skeleton } from '@mui/joy'
import { Link as ReactRouterLink } from 'react-router-dom'

import { ChevronIcon } from '~/shared/ui/Icons'
import { Title } from './styled'

export type BreadcrumbsList = { title: string; link?: string }[]

type BreadcrumbsProps = {
  list: BreadcrumbsList
  lastLoading?: boolean
}

export function Breadcrumbs({ list, lastLoading = false }: BreadcrumbsProps) {
  return (
    <JoyBreadcrumbs
      separator={<ChevronIcon />}
      sx={{
        '--Breadcrumbs-gap': '12px',
        padding: '12px 0 20px 10px',
      }}
    >
      {list.map(({ title, link }) => (
        <Box key={title || '-'}>
          {link && (
            <Link component={ReactRouterLink} color='neutral' to={link}>
              <Title>{title}</Title>
            </Link>
          )}

          {!link && (
            <Title sx={{ position: 'relative' }}>
              <Skeleton
                loading={lastLoading}
                sx={{ borderRadius: '8px', minWidth: '100px' }}
              />
              {title || '-'}
            </Title>
          )}
        </Box>
      ))}
    </JoyBreadcrumbs>
  )
}
