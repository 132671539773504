import React from 'react'

import { SettingsTelematic } from '~/features/SettingsTelematic'
import { Breadcrumbs, BreadcrumbsList } from '~/shared/ui/Breadcrumbs'
import { Container, PageContainer, PageTitle } from '~/shared/ui/Layout'

const breadcrumbs: BreadcrumbsList = [
  { title: 'Автомобили', link: '/' },
  { title: 'Настройки телематики' },
]

export function SettingsTelematicPage() {
  return (
    <PageContainer title='Настройки телематики'>
      <Container isHeader>
        <Breadcrumbs list={breadcrumbs} />
        <PageTitle>Настройки телематики</PageTitle>
      </Container>

      <SettingsTelematic />
    </PageContainer>
  )
}
