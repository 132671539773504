import * as z from 'zod'

import { Option } from '~/shared/config/constants'
import { CarStatusEnum } from '~/shared/config/enums'
import { dateSchema } from '~/shared/lib/schemas'
import { ApiModel } from './core'

const schema = z.object({
  updatedAt: dateSchema,
  plateNumber: z.string(),
  vin: z.string(),
  status: z.nativeEnum(CarStatusEnum),
  telematicAccount: z.string(),
  extId: z.string(),
})

export type CarAttributes = z.infer<typeof schema>

export class Car extends ApiModel<typeof schema, CarAttributes> {
  static jsonApiType = 'cars'

  static schema = schema

  getPlateNumber() {
    return this.getAttribute('plateNumber')
  }

  getOption(): Option {
    const plateNumber = this.getPlateNumber()
    return {
      id: plateNumber,
      label: plateNumber,
    }
  }

  static async fetchOptions(search: string) {
    const response = await Car.where('plateNumber', search).get(1)
    return response.getData().map((o) => o.getOption())
  }
}
