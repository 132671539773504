import { Chip, ChipProps } from '@mui/joy'
import { useMemo } from 'react'
import { myPalette } from '~/shared/lib/theme'

type ChipStatusProps = {
  label: string
  variant: 'green' | 'red' | 'gray'
} & Omit<ChipProps, 'variant'>

export function ChipStatus({ label, variant, ...props }: ChipStatusProps) {
  const customVariant = useMemo(() => {
    if (variant === 'green') {
      return {
        backgroundColor: myPalette.green['100'],
        color: myPalette.green['600'],
      }
    }
    if (variant === 'red') {
      return {
        backgroundColor: myPalette.red['100'],
        color: myPalette.red['600'],
      }
    }
    if (variant === 'gray') {
      return {
        backgroundColor: myPalette.gray['100'],
        color: myPalette.gray['700'],
      }
    }
  }, [variant])

  return (
    <Chip
      {...props}
      sx={{
        ...customVariant,
        ...props.sx,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {label}
    </Chip>
  )
}
