import { uniq } from 'lodash'
import { useMemo } from 'react'
import { usePermissions } from '~/entities/viewer'

export const usePermissionsPage = () => {
  const {
    canUsersView,
    canUsersUpdate,
    canRolesUpdate,
    canRolesView,
    canSettingsView,
    canConfigView,
  } = usePermissions()

  const notPermissions = useMemo(() => {
    let data: string[] = []

    if (!canSettingsView) data = [...data, '/settings/*']

    if (!canUsersView)
      data = [...data, '/settings/employees', '/settings/employees/*']
    if (!canUsersUpdate) data = [...data, '/settings/employees/new']

    if (!canRolesView) data = [...data, '/settings/roles', '/settings/roles/*']
    if (!canRolesUpdate) data = [...data, '/settings/roles/new']

    if (!canConfigView) data = [...data, '/settings-telematic']

    return uniq<string>(data)
  }, [
    canSettingsView,
    canUsersView,
    canUsersUpdate,
    canRolesView,
    canRolesUpdate,
    canConfigView,
  ])

  return { notPermissions }
}
