import { createDomain, sample } from 'effector'
import { createGate } from 'effector-react'
import { CarWhitelist, CarWhitelistOption } from '~/shared/api'
import { Option } from '~/shared/config/constants'
import { logger } from '~/shared/lib/logger'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { formButtonsModel } from '~/shared/ui/Form'

const domain = createDomain('features.settings.telematic')
export const Gate = createGate()

export const requestCarWhitelistFx = domain.createEffect({
  async handler() {
    const res = await CarWhitelist.limit(500).get()
    return res.getData().map((o) => o.getOption())
  },
})

export const requestCarWhitelistWithoutLoadingFx = domain.createEffect({
  async handler() {
    const res = await CarWhitelist.limit(500).get()
    return res.getData().map((o) => o.getOption())
  },
})

export const $requestCarWhitelistError = domain
  .createStore<boolean>(false)
  .on(requestCarWhitelistFx.failData, () => true)
  .on(Gate.close, () => false)

sample({
  clock: Gate.open,
  target: requestCarWhitelistFx,
})

sample({
  clock: requestCarWhitelistFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: 'Ошибка получения автомобилей для исключения блокировки',
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

export const carsBatchAdd = domain.createEvent<Option[]>()
export const carsBatchAddFx = domain.createEffect<Option[], void>({
  async handler(cars) {
    const promises = cars.map(({ label }) => {
      const car = new CarWhitelist({ plateNumber: label as string })
      return car.save()
    })
    await Promise.all(promises)
  },
})

sample({
  clock: carsBatchAddFx.doneData,
  fn() {
    return {
      message: 'Автомобили добавлены',
      variant: 'success' as const,
    }
  },
  target: snackbarEnqueued,
})

sample({
  clock: carsBatchAddFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: 'Ошибка добаления автомобилей',
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

sample({
  clock: carsBatchAdd,
  target: carsBatchAddFx,
})

export const carsBatchDelete = domain.createEvent<CarWhitelistOption[]>()
export const carsBatchDeleteFx = domain.createEffect<
  CarWhitelistOption[],
  void
>({
  async handler(cars) {
    const promises = cars.map(({ deleteId }) => {
      const car = new CarWhitelist({}, deleteId)
      return car.delete()
    })
    await Promise.all(promises)
  },
})

sample({
  clock: carsBatchDeleteFx.doneData,
  fn() {
    return {
      message: 'Автомобили удаленны',
      variant: 'warning' as const,
    }
  },
  target: snackbarEnqueued,
})

sample({
  clock: carsBatchDeleteFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: 'Ошибка удаления автомобилей',
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

sample({
  clock: carsBatchDelete,
  target: carsBatchDeleteFx,
})

sample({
  clock: [carsBatchAddFx.doneData, carsBatchDeleteFx.doneData],
  target: requestCarWhitelistWithoutLoadingFx,
})

export const $carWhitelists = domain
  .createStore<CarWhitelistOption[]>([])
  .on(
    [
      requestCarWhitelistFx.doneData,
      requestCarWhitelistWithoutLoadingFx.doneData,
    ],
    (_, cars) => cars,
  )
  .on(Gate.close, () => [])

sample({
  clock: requestCarWhitelistWithoutLoadingFx.doneData,
  target: formButtonsModel.editingEnded,
})
