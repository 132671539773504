import { usePermissions } from '~/entities/viewer'
import { canShowObject } from '~/shared/lib/utils'

type Header = { label: string; to: string; parent?: string }

export const useHeader = (): Header[] => {
  const { canSettingsView } = usePermissions()
  return [
    {
      label: 'Автомобили',
      to: '/',
    },
    ...canShowObject(canSettingsView, {
      label: 'Настройки',
      to: '/settings/employees',
      parent: '/settings',
    }),
  ]
}
