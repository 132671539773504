import { useCallback } from 'react'

import { usePermissions } from '~/entities/viewer'
import { Car } from '~/shared/api'
import { AsyncGrid } from '~/shared/ui/AsyncGrid'
import type { FetchRowsFn } from '~/shared/ui/AsyncGrid/types'

import { Button } from '~/shared/ui/Buttons'
import { SettingsIcon } from '~/shared/ui/Icons'
import { columns } from './columns'
import { filters } from './filters'
import { GridRow } from './types'

export function ListCars() {
  const { canConfigView } = usePermissions()

  const fetchRows = useCallback<FetchRowsFn<GridRow>>(
    async (page, pageSize, filters = []) => {
      let builder = Car.limit(pageSize)

      filters.forEach(({ key, value }) => {
        builder = builder.where(key, value)
      })

      const response = await builder.get(page)
      const data = response.getData()
      const rows = data.map((car) => ({
        ...car.getAttributes(),
        id: car.getApiId() as string,
      }))

      const httpClientResponse = response.getHttpClientResponse()
      const axiosResponse = httpClientResponse?.getUnderlying()
      const total = axiosResponse?.data?.meta?.page?.total || 0

      return {
        rows,
        total,
      }
    },
    [],
  )

  return (
    <AsyncGrid<GridRow>
      gridKey='cars'
      fetchRows={fetchRows}
      columns={columns}
      filters={filters}
      title='Автомобили'
      headerExtra={
        <Button
          startDecorator={<SettingsIcon />}
          to='/settings-telematic'
          show={canConfigView}
        >
          Настройки
        </Button>
      }
    />
  )
}
