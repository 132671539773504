import { styled, Textarea as JoyTextarea } from '@mui/joy'
import { myPalette } from '~/shared/lib/theme'

export const Textarea = styled(JoyTextarea)`
  margin: 2px 0;
  background: ${myPalette.white.main};
  background-color: ${myPalette.white.main};
  --Textarea-decoratorColor: ${myPalette.white.main};
  --Textarea-radius: 12px;
  border: 1px solid ${myPalette.gray['400']};

  &.Mui-disabled {
    border: 1px solid ${myPalette.gray['400']};
  }

  &.Mui-focused:before {
    box-shadow: 0 0 0 1px ${myPalette.brand['300']},
      0 0 0 4px ${myPalette.purpure['100']};
  }

  &.Mui-focused.MuiInput-colorDanger:before {
    box-shadow: 0 0 0 2px 2px ${myPalette.red['700']};
  }
`
