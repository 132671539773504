import {
  styled,
  Input as JoyInput,
  Box,
  Checkbox as JoyCheckbox,
  Autocomplete as JoyAutocomplete,
} from '@mui/joy'
import { myPalette } from '~/shared/lib/theme'

export const Input = styled(JoyInput)`
  margin: 2px 0;
  background: ${myPalette.white.main};
  background-color: ${myPalette.white.main};
  --Input-decoratorColor: ${myPalette.white.main};
  --Input-radius: 12px;
  border: 1px solid ${myPalette.gray['400']};

  &.Mui-disabled {
    border: 1px solid ${myPalette.gray['400']};
  }

  &.Mui-focused:before {
    box-shadow: 0 0 0 1px ${myPalette.brand['300']},
      0 0 0 4px ${myPalette.purpure['100']};
  }

  &.Mui-error {
    border: 1px solid ${myPalette.red['700']};

    &.Mui-focused:before {
      box-shadow: 0 0 0 1px ${myPalette.red['600']},
        0 0 2px 2px ${myPalette.red['700']};
    }
  }
`

export const DatePickerPopper = styled(Box)`
  z-index: 6;

  .react-datepicker {
    box-shadow: 0 1px 10px 1px ${myPalette.gray['100']};
    border: 1px solid ${myPalette.gray['400']};
    background: ${myPalette.white.main};
    padding: 12px;
    border-radius: 18px;
  }

  .react-datepicker__header {
    background: none;
    border: none;
  }

  .react-datepicker__day {
    font-size: 16px;
    border-radius: 16px;
  }

  .react-datepicker__day--today {
    background: ${myPalette.brand['500']};
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-range,
  .react-datepicker__day--in-selecting-range {
    background: ${myPalette.brand['600']};
    color: ${myPalette.white.main};
  }

  .react-datepicker__day {
    &:hover {
      background: ${myPalette.brand['600']};
      color: ${myPalette.white.main};
    }
  }

  .react-datepicker__day--outside-month {
    color: ${myPalette.gray['400']};
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__navigation {
    border-radius: 16px;

    &:hover {
      background: ${myPalette.brand['600']};
    }
  }

  .react-datepicker__navigation-icon:before {
    border-color: ${myPalette.gray['300']};
  }

  .react-datepicker__navigation--next {
    right: 12px;
    top: 14px;
  }

  .react-datepicker__navigation--previous {
    left: 12px;
    top: 14px;
  }

  .react-datepicker__navigation-icon--next,
  .react-datepicker__navigation-icon--previous {
    &:before {
      top: 6px;
      left: -6px;
      height: 12px;
      width: 12px;
    }
  }

  .react-datepicker__current-month {
    margin-bottom: 12px;
    text-transform: capitalize;
  }
`

export const Autocomplete = styled(JoyAutocomplete)`
  margin: 2px 0;
  background: ${myPalette.white.main};
  background-color: ${myPalette.white.main};
  --Input-decoratorColor: ${myPalette.white.main};
  --Input-radius: 12px;
  border: 1px solid ${myPalette.gray['400']};

  &.Mui-disabled {
    border: 1px solid ${myPalette.gray['400']};
  }

  &.Mui-focused:before {
    box-shadow: 0 0 0 1px ${myPalette.brand['300']},
      0 0 0 4px ${myPalette.purpure['100']};
  }

  &.Mui-error {
    border: 1px solid ${myPalette.red['700']};

    &.Mui-focused:before {
      box-shadow: 0 0 0 1px ${myPalette.red['600']},
        0 0 2px 2px ${myPalette.red['700']};
    }
  }
` as typeof JoyAutocomplete

export const Checkbox = styled(JoyCheckbox)`
  .MuiCheckbox-checkbox {
    border-radius: 6px;
  }

  .MuiCheckbox-checkbox.Mui-checked {
    background: ${myPalette.brand['600']};
  }
  .MuiCheckbox-checkbox.Mui-disabled {
    background: ${myPalette.brand['400']};
    svg {
      fill: ${myPalette.white.main};
    }
  }
`
