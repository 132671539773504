export function SettingsIcon() {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.5 6.66663L12.5 6.66663M12.5 6.66663C12.5 8.04734 13.6193 9.16663 15 9.16663C16.3807 9.16663 17.5 8.04734 17.5 6.66663C17.5 5.28591 16.3807 4.16663 15 4.16663C13.6193 4.16663 12.5 5.28591 12.5 6.66663ZM7.5 13.3333L17.5 13.3333M7.5 13.3333C7.5 14.714 6.38071 15.8333 5 15.8333C3.61929 15.8333 2.5 14.714 2.5 13.3333C2.5 11.9526 3.61929 10.8333 5 10.8333C6.38071 10.8333 7.5 11.9526 7.5 13.3333Z'
        stroke='white'
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
