import { zodResolver } from '@hookform/resolvers/zod'
import { Grid } from '@mui/joy'
import { useStore } from 'effector-react'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { FormValues, employeeModel } from '~/entities/Employee'
import { Role } from '~/shared/api'
import {
  FormButtons,
  formButtonsModel,
  FormProvider,
  FormTitle,
  TextInput,
  AsyncAutocompleteInput,
} from '~/shared/ui/Form'
import { Container } from '~/shared/ui/Layout'

export type FormProps = {
  isCreating?: boolean
  canEdit?: boolean
  isSubmitting?: boolean
  isLoading?: boolean
  onSuccess?: (formValues: FormValues) => void
  defaultValues?: Partial<FormValues>
}

export function Form({
  isCreating,
  canEdit,
  isSubmitting,
  isLoading,
  defaultValues,
  onSuccess,
}: FormProps) {
  const isEditing = useStore(formButtonsModel.$isEditing)
  const disabled = (!isEditing && !isCreating) || isSubmitting

  const form = useForm<FormValues>({
    resolver: zodResolver(employeeModel.formSchema),
    defaultValues,
  })

  useEffect(() => {
    if (defaultValues) form.reset(defaultValues)
    // eslint-disable-next-line
  }, [defaultValues, isEditing])

  return (
    <FormProvider form={form} onSuccess={onSuccess} canEdit={canEdit}>
      <Container>
        <FormTitle>Информация</FormTitle>
        <Grid container spacing={2.5}>
          <Grid xs={6}>
            <TextInput
              label='ФИО'
              name='name'
              skeletonShow={isLoading}
              readOnly={disabled}
            />
          </Grid>
          <Grid xs={6}>
            <TextInput
              label='E-mail'
              name='email'
              skeletonShow={isLoading}
              readOnly={disabled}
            />
          </Grid>
        </Grid>
      </Container>

      <Container>
        <FormTitle>Роли</FormTitle>
        <Grid container spacing={2.5}>
          <AsyncAutocompleteInput
            name='rolesIds'
            placeholder='Добавить роль'
            fetchOptions={Role.fetchOptions}
            skeletonShow={isLoading}
            readOnly={disabled}
            multiple
            autocompleteXs={6}
          />
        </Grid>
      </Container>

      <FormButtons loading={isSubmitting} isCreating={isCreating} />
    </FormProvider>
  )
}
