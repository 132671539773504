import { GridColDef } from '@mui/x-data-grid'
import { ChipStatus } from 'src/shared/ui/Chips'
import { carStatusEnumLabels } from '~/shared/config/constants'
import { CarStatusEnum } from '~/shared/config/enums'
import { TMS_DOMAIN } from '~/shared/config/env'
import { ColumnDate, ColumnLink } from '~/shared/ui/AsyncGrid'
import { GridRow } from './types'

export const columns: GridColDef<GridRow>[] = [
  {
    headerName: 'Гос. номер',
    field: 'plateNumber',
    flex: 1,
    minWidth: 300,
    renderCell: ({ row }) => (
      <ColumnLink href={`${TMS_DOMAIN}cars/${row.extId}`}>
        {row.plateNumber}
      </ColumnLink>
    ),
  },
  {
    headerName: 'VIN',
    field: 'vin',
    sortable: false,
    flex: 1,
  },
  {
    headerName: 'Статус',
    field: 'status',
    flex: 1,
    renderCell: ({ row }) =>
      row.status && (
        <ChipStatus
          variant={row.status === CarStatusEnum.BLOCKED ? 'red' : 'green'}
          label={carStatusEnumLabels[row.status]}
        />
      ),
  },
  {
    headerName: 'Дата обновления',
    field: 'updatedAt',
    flex: 1,
    renderCell: ({ row }) => (
      <ColumnDate value={row.updatedAt} type='dateTime' />
    ),
  },
  {
    headerName: 'Аккаунт телематики',
    field: 'telematicAccount',
    sortable: false,
    flex: 1,
  },
]
