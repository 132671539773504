import React from 'react'

import { ListCars } from '~/features/Cars'
import { Container, PageContainer } from '~/shared/ui/Layout'

export function CarsListPage() {
  return (
    <PageContainer title='Автомобили'>
      <Container>
        <ListCars />
      </Container>
    </PageContainer>
  )
}
