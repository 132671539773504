import { useStore } from 'effector-react'
import React from 'react'
import { Form } from '~/entities/Employee'
import { formSubmitted, createFx } from './model'

export const AddEmployee = () => {
  const isCreating = useStore(createFx.pending)

  return <Form isSubmitting={isCreating} isCreating onSuccess={formSubmitted} />
}
