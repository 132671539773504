import { userOptionsStore } from '~/shared/api'

export const usePermissions = () => {
  const permissions = userOptionsStore.permissions || []

  const checkPermissions = (permission: string) =>
    permissions.includes(permission)

  const canUsersUpdate = checkPermissions('update users')
  const canUsersView = checkPermissions('view users')

  const canRolesUpdate = checkPermissions('update roles')
  const canRolesView = checkPermissions('view roles')

  const canAuditsView = checkPermissions('view audits')

  const canSettingsView = canUsersView || canRolesView

  const canConfigView = checkPermissions('view config')
  const canConfigUpdate = checkPermissions('update config')

  return {
    canUsersUpdate,
    canUsersView,
    canRolesUpdate,
    canRolesView,
    canAuditsView,
    canSettingsView,
    canConfigView,
    canConfigUpdate,
  }
}
