import { Accordion, AccordionGroup, Stack, AccordionDetails } from '@mui/joy'
import { ReactNode, useState } from 'react'
import { Filter, Filters } from '~/shared/ui/AsyncGrid/Filters'
import { SettingsColumns } from '~/shared/ui/AsyncGrid/SettingsColumns'
import { Breadcrumbs, BreadcrumbsList } from '~/shared/ui/Breadcrumbs'
import { Button } from '~/shared/ui/Buttons'
import { FilterFunnelIcon } from '~/shared/ui/Icons'
import { ResetResize } from '../ResizableHeader'
import { Wrapper, Title, SmallTitle } from './styled'

type HeaderProps = {
  title?: ReactNode | string
  smallTitle?: ReactNode | string
  breadcrumbs?: BreadcrumbsList
  filters?: Filter[]
  extra?: ReactNode
  onFiltersChange: () => void
}

export function Header({
  title,
  smallTitle,
  breadcrumbs,
  filters = [],
  extra,
  onFiltersChange,
}: HeaderProps) {
  const canFilter = Boolean(filters.length)
  const [isExpended, toggleExpended] = useState(false)
  const [isRenderFilters, setIsRenderFilters] = useState(false)

  const handleExpanded = () => {
    toggleExpended(!isExpended)
    setIsRenderFilters(true)
  }

  return (
    <>
      <AccordionGroup>
        {breadcrumbs && <Breadcrumbs list={breadcrumbs} />}
        <Accordion expanded={isExpended} sx={{ p: 0 }}>
          <Wrapper>
            <Stack direction='row' spacing={2} alignItems='center'>
              {title && <Title>{title}</Title>}
              {smallTitle && <SmallTitle>{smallTitle}</SmallTitle>}
              {canFilter && (
                <Button
                  onClick={handleExpanded}
                  variant='gray'
                  startDecorator={<FilterFunnelIcon />}
                >
                  Фильтры
                </Button>
              )}
              <SettingsColumns />
            </Stack>

            <Stack direction='row' spacing={2} alignItems='center'>
              <ResetResize />

              {extra}
            </Stack>
          </Wrapper>

          {canFilter && (
            <AccordionDetails>
              {isRenderFilters && (
                <Filters onFiltersChange={onFiltersChange} filters={filters} />
              )}
            </AccordionDetails>
          )}
        </Accordion>
      </AccordionGroup>
    </>
  )
}
