export enum EventAuditEnum {
  CREATED = 'created',
  UPDATED = 'updated',
  DELETED = 'deleted',
  RESTORED = 'restored',
}

export enum CarStatusEnum {
  BLOCKED = 'BLOCKED',
  UNLOCKED = 'UNLOCKED',
}
